import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TokenTable from "~/components/TokenTable/TokenTable";
export const _frontmatter = {
  "menuLabel": "Shape",
  "sortOrder": 6.3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Shape`}</h1>
    <p className="p-large">Shape values help differentiate UI elements from one another.</p>
    <p>{`Border radii change the shape of UI elements. Border widths emphasize elements by providing contrast against the background.  Using a border can help provide an UI element pass minimum contrast accessibility standards (3:1 contrast ratio).`}</p>
    <blockquote>
      <p parentName="blockquote">{`We've scoped these down into a collection you can use: `}<a parentName="p" {...{
          "href": "/tokens/border-radius"
        }}>{`border radius tokens`}</a>{` and `}<a parentName="p" {...{
          "href": "/tokens/border-width"
        }}>{`border width tokens.`}</a>{`.`}</p>
    </blockquote>
    <TokenTable tokenGroup="BorderWidth" showTitle={true} mdxType="TokenTable" />
    <TokenTable tokenGroup="BorderRadius" hideSwitcher={true} showTitle={true} mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      